import React from "react"
import Layout from "../components/layout"
import Personal from "../components/personal"

export default function PersonalPage() {
  return (
    <Layout>
      <Personal />
    </Layout>
  )
}
