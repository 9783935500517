import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Container from "./container"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

export default function Personal() {
  const data = useStaticQuery(graphql`
    {
      wpPage(databaseId: { eq: 26 }) {
        id
        personal {
          medarbetare {
            namn
            titel
            bild {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    aspectRatio: 0.8
                    formats: JPG
                    placeholder: BLURRED
                  )
                }
              }
            }
          }
        }
        title
      }
    }
  `)
  const { personal } = data.wpPage

  return (
    <div>
      <div className="bg-primary/30">
        <Container>
          <div className="space-y-4">
            <h1 className="page-header">Vi som jobbar här</h1>
            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 md:gap-8 ">
              {personal.medarbetare.map((post, i) => {
                return <Person key={i} post={post} />
              })}
            </div>
          </div>
        </Container>
      </div>
    </div>
  )
}

function Person({ post }) {
  const { namn, titel, bild } = post
  const img = bild.localFile.childImageSharp

  return (
    <div className="text-primary flex flex-col gap-4">
      <GatsbyImage
        image={getImage(img)}
        className="border-[.5rem] xl:border-[1rem] border-gray-50"
      />
      <div className="space-y-2">
        <h2 className="text-lg md:text-2xl">{namn}</h2>
        <p>{titel}</p>
      </div>
    </div>
  )
}
